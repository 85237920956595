"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, closest, findAllIn} from "@elements/dom-utils";

export function init () {

    onFind('.js-quickfinder', function (quickfinder) {

        onFind('.js-quickfinder__input', function (item) {

            let firstFormCheck = item.children[0];
            let container = closest('.js-dropdown-select', item);
            let input = findIn('.js-dropdown-select__text', container);
            let selected = findIn('.js-dropdown-select__item-input', firstFormCheck);
            let hiddenInput = findIn('.js-dropdown-select__hidden-input', quickfinder);
            if(input.getAttribute('id') === "-1"){
                input.innerHTML = findIn('.js-dropdown-select__item', firstFormCheck).innerHTML;
                hiddenInput.setAttribute('value', selected.value);
                hiddenInput.innerHTML = "test";
                // copy id from selection into input button

            }
        })
    });

    onFind('.js-dropdown-select__item', function (item) {
        let container = closest('.js-dropdown-select', item);
        on('click', function () {
            onFind('.js-dropdown-select__input',  function (container) {
            container.value = item.getAttribute('data-value');
            })
            findIn('.js-dropdown-select__text', container).innerHTML = item.innerHTML;
        }, item);
    })
}