
import {find, findIn, setAttribute, removeAttribute, on, trigger, hasClass, addClass, removeClass, toggleClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
//recaptcha v3

export function init() {
    onFind('.js-show-password', function(baseElement) {
        let passwordInput = findIn('.js-show-password__input', baseElement);
        let passwordInputType = passwordInput.getAttribute('type');
        let passwordInputIcon = findIn('.js-show-password__icon', baseElement);
        on('click', function() {
            passwordInputType = passwordInputType === 'password' ? 'text' : 'password';
            passwordInput.setAttribute('type', passwordInputType);
            toggleClass('show-password--show', passwordInputIcon);
        }, passwordInputIcon);
    });
}

