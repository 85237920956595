import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, setAttribute, removeAttribute} from '@elements/dom-utils';

export function init () {
    onFind('.js-alert', function(baseElement) {

        let closeBtn = findIn('.js-alert__close', baseElement);
        let alertId = baseElement.id;

        if (!sessionStorage.getItem('alert-bar-' + alertId)) {
            removeAttribute('hidden', baseElement);
        }
        on('click', function () {
            sessionStorage.setItem('alert-bar-' + alertId,'closed');
            setAttribute('hidden', 'hidden', baseElement);
        }, closeBtn);

    });
}