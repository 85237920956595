import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, hasClass, removeClass, addClass} from "@elements/dom-utils";

export function init() {
    onFind('.js-account-login', function (element) {
        let toggle = findIn('.js-account-login-toggle', element);
        let window = findIn('.js-account-login-window', element);

        if(toggle) {
            on('click', function(event) {
                if(hasClass('is-active', window)) {
                    removeClass('is-active', window);
                } else {
                    addClass('is-active', window);
                }
            }, toggle);
        }
    });
}