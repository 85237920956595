import {onFind} from "@elements/init-modules-in-scope";
import {on, find, findIn} from "@elements/dom-utils";
import {trackEvent} from "@elements/tracking/src/gtm";

export function init() {
    onFind('.js-tracking-extension--quickfinder', function (element) {
        on('submit', function (evt) {
            let areaValue = find('.js-tracking-extension--quickfinder-location')?.innerText || "";
            let categoryValue = find('.js-tracking-extension--quickfinder-category')?.innerText || "";
            let data = {
                datalayer: {
                    event: 'quickfinder_interaction',
                    area: areaValue,
                    ticketCategory: categoryValue
                }
            };

            trackEvent(data, {debug: true});
        }, element);
    });

    onFind('.js-tracking-extension--payment', function (element) {
        let trackingKey = element.getAttribute(`data-payment-tracking-key`);
        let trackingObject = _trackingData[trackingKey];

        if(trackingObject) {
            on('submit', function (evt) {
                let checkedRadio = findIn('.js-tracking-extension--payment-radio:checked', element);
                if(checkedRadio){
                    let label = checkedRadio.dataset.paymentTrackingLabel || "";
                    trackingObject.datalayer.ecommerce.payment_type = label;
                }

                trackEvent(trackingObject, {debug: true});
            }, element);
        } else {
            console.warn("Tracking object not found for key: " + trackingKey);
        }

    });
}