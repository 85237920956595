import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";
import {on, find, addClass, removeClass} from "@elements/dom-utils";
import { onEnterViewPort  } from "@elements/viewport-utils"

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// import ScrollSpy from 'bootstrap/js/dist/scrollspy';
// onFind('[data-bs-spy="scroll"]',function (element) {
//     new ScrollSpy(document.body, {
//         target: element.getAttribute('data-bs-target'),
//         offset: 150
//     });
// });

// import * as anchorNav from '@elements/anchor-nav';
// anchorNav.init();

import * as affix from "@elements/affix";
// import {EVENTS} from "@elements/affix";
// affix.init();
affix.init({mediaQuery: '(min-width: 768px)'});
// onFind('.js-affix', ((affixElement) => {
//     console.log(affixElement)
//     on(EVENTS.AFFIX, (evt) => {
//         console.log('is affix')
//         addClass('is-affix-content', document.body);
//     }, affixElement);
//
//     on(EVENTS.DETACH, (evt) => {
//         console.log('is not affix')
//         removeClass('is-affix-content', document.body);
//     }, affixElement);
// }));

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({
    scrollOffset: (matchMedia('(min-width: 768px)').matches ? -500 : -280),
});

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init({
    submitOnChange:true
});

//todo
let quickfinder;
onFind('.quickfinder', function (element) {
    quickfinder = element;
})

onFind('.js-scroll-content', function (element) {
    let fadeoutThreshold = window.innerHeight / 4
    let bottomMainFromTop = find("main")?.getBoundingClientRect().bottom - window.innerHeight - fadeoutThreshold
    const mainElement = find("main")

    window.addEventListener("resize", () => {
        bottomMainFromTop = find("main")?.getBoundingClientRect().bottom - window.innerHeight - fadeoutThreshold
    })

    handleMainBackground({ mainElement, bottomMainFromTop })
    window.addEventListener('scroll', function() {
        if (quickfinder) {
            if ((element.getBoundingClientRect().top + 100) <= window.innerHeight / 1.8) {
                addClass('is-affix-content', document.body);
            } else {
                removeClass('is-affix-content', document.body);
            }
        } else {
            if ((element.getBoundingClientRect().top + 400) <= window.innerHeight / 1.8) {
                addClass('is-affix-content', document.body);
            } else {
                removeClass('is-affix-content', document.body);
            }
        }

        bottomMainFromTop = find("main")?.getBoundingClientRect().bottom - window.innerHeight - fadeoutThreshold
        handleMainBackground({ mainElement, bottomMainFromTop })
    }, false);

    function handleMainBackground({mainElement, bottomMainFromTop}) {
        if(mainElement && bottomMainFromTop > 0) {
            removeClass("bg-white", mainElement)
        } else if(mainElement) {
            addClass("bg-white", mainElement)
        }
    }
});
